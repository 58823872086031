import { Box, Paper, Typography } from '@mui/material'
import { IconComments } from 'assets/icons'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui/inputs/button'

type Props = Readonly<{
  onLeaveComment: () => void
}>

export const EmptyCommentsSection = ({ onLeaveComment }: Props) => {
  const { t } = useTranslation()

  return (
    <Paper
      sx={{
        padding: '32px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <EmptyCommentsContent />

      <Box mt="24px">
        <Button onClick={onLeaveComment}>
          {t('comments.empty.add_button')}
        </Button>
      </Box>
    </Paper>
  )
}

export const EmptyCommentsContent = () => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <IconComments
        sx={{ color: 'primary.main', width: '60px', height: '60px' }}
      />

      <Typography variant="h5" mt="16px">
        {t('comments.empty.title')}
      </Typography>

      <Typography
        variant="body2"
        textAlign="center"
        color="mineShaft.800"
        mt="8px"
      >
        {t('comments.empty.subtitle')}
      </Typography>
    </Box>
  )
}
