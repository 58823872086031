import { Box, Stack, Typography } from '@mui/material'
import { CustomFieldWithValue } from 'api'
import { DetailsItemContainer } from 'components/templates/details-template'
import { useDateAndTimeFormat } from 'lib/context'
import { useTranslation } from 'react-i18next'

type Props = Readonly<{
  customFields: Array<CustomFieldWithValue>
}>

export const CustomFieldsAssignedSection = ({ customFields }: Props) => {
  const { t } = useTranslation()
  const { formatDate } = useDateAndTimeFormat()

  if (customFields.length === 0) return null

  return (
    <DetailsItemContainer title={t('common.custom_fields')}>
      <Box mt={3}>
        <Stack spacing={3}>
          {[...customFields]
            .sort(
              (fieldA, fieldB) =>
                fieldA.fieldDetails.fieldOrder - fieldB.fieldDetails.fieldOrder,
            )
            .map(({ fieldDetails, fieldValue }) => (
              <Stack key={fieldDetails.fieldId} direction="row" gap={1}>
                <Typography color="mineShaft.700" variant="body2">
                  {fieldDetails.fieldName}:
                </Typography>
                <Typography color="mineShaft.main" variant="body1">
                  {fieldDetails.fieldType === 'DATE'
                    ? formatDate(new Date(fieldValue))
                    : (fieldValue as string)}
                </Typography>
              </Stack>
            ))}
        </Stack>
      </Box>
    </DetailsItemContainer>
  )
}
