import { GlobalStyles, useTheme } from '@mui/material'
import { ToastContainerId } from 'app/enums'
import { IconClose } from 'assets/icons'
import {
  ToastContainer as Container,
  ToastContainerProps,
} from 'react-toastify'
import { IconButton } from 'ui/inputs/icon-button'

export const ToastContainer = (props: ToastContainerProps) => {
  const theme = useTheme()

  return (
    <>
      <GlobalStyles
        styles={{
          '.Toastify__progress-bar--info': {
            background: theme.palette.info.main,
          },
          '.Toastify__progress-bar--success': {
            background: theme.palette.primary.main,
          },
          '.Toastify__progress-bar--warning': {
            background: theme.palette.warning.main,
          },
          '.Toastify__progress-bar--error': {
            background: theme.palette.error.main,
          },
        }}
      />

      <Container
        enableMultiContainer
        containerId={ToastContainerId.System}
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        draggable={false}
        icon={false}
        closeButton={({ closeToast }) => (
          <IconButton
            onClick={closeToast}
            sx={{
              position: 'absolute',
              top: '5px',
              right: '4px',
              color: 'greyBlue.main',
            }}
          >
            <IconClose />
          </IconButton>
        )}
        style={{
          width: 320,
          zIndex: theme.zIndex.snackbar,
          bottom: 76,
          ...props.style,
        }}
        bodyStyle={{
          width: '100%',
          padding: 0,
          margin: 0,
        }}
        toastStyle={{
          background: '#fff',
          boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.2)',
          padding: 0,
          minHeight: 'auto',
          cursor: 'auto',
        }}
        progressStyle={{
          height: '2px',
        }}
        {...props}
      />
    </>
  )
}
