import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconTreeDots = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M7.99992 8.66699C8.36811 8.66699 8.66659 8.36852 8.66659 8.00033C8.66659 7.63214 8.36811 7.33366 7.99992 7.33366C7.63173 7.33366 7.33325 7.63214 7.33325 8.00033C7.33325 8.36852 7.63173 8.66699 7.99992 8.66699Z"
      fill="currentColor"
    />
    <path
      d="M7.99992 4.00033C8.36811 4.00033 8.66659 3.70185 8.66659 3.33366C8.66659 2.96547 8.36811 2.66699 7.99992 2.66699C7.63173 2.66699 7.33325 2.96547 7.33325 3.33366C7.33325 3.70185 7.63173 4.00033 7.99992 4.00033Z"
      fill="currentColor"
    />
    <path
      d="M7.99992 13.3337C8.36811 13.3337 8.66659 13.0352 8.66659 12.667C8.66659 12.2988 8.36811 12.0003 7.99992 12.0003C7.63173 12.0003 7.33325 12.2988 7.33325 12.667C7.33325 13.0352 7.63173 13.3337 7.99992 13.3337Z"
      fill="currentColor"
    />
    <path
      d="M7.99992 8.66699C8.36811 8.66699 8.66659 8.36852 8.66659 8.00033C8.66659 7.63214 8.36811 7.33366 7.99992 7.33366C7.63173 7.33366 7.33325 7.63214 7.33325 8.00033C7.33325 8.36852 7.63173 8.66699 7.99992 8.66699Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99992 4.00033C8.36811 4.00033 8.66659 3.70185 8.66659 3.33366C8.66659 2.96547 8.36811 2.66699 7.99992 2.66699C7.63173 2.66699 7.33325 2.96547 7.33325 3.33366C7.33325 3.70185 7.63173 4.00033 7.99992 4.00033Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99992 13.3337C8.36811 13.3337 8.66659 13.0352 8.66659 12.667C8.66659 12.2988 8.36811 12.0003 7.99992 12.0003C7.63173 12.0003 7.33325 12.2988 7.33325 12.667C7.33325 13.0352 7.63173 13.3337 7.99992 13.3337Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
