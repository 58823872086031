import { useTranslation } from 'react-i18next'

export const useGetNameWithStatus = () => {
  const { t } = useTranslation()
  const inactiveStatus = `(${t('common.inactive')})`
  const getNameWithStatus = (name: string, active?: boolean) => {
    return `${name} ${active === false ? inactiveStatus : ''}`
  }
  return { getNameWithStatus }
}
