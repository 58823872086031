import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconResize = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 17"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M13.3333 9.83333V11.7C13.3333 12.4467 13.3333 12.8201 13.188 13.1053C13.0602 13.3562 12.8562 13.5602 12.6053 13.688C12.3201 13.8333 11.9467 13.8333 11.2 13.8333H9.33333M6.66667 3.16667H4.8C4.05326 3.16667 3.6799 3.16667 3.39468 3.31199C3.1438 3.43982 2.93982 3.6438 2.81199 3.89468C2.66667 4.1799 2.66667 4.55326 2.66667 5.3V7.16667M10 6.5L14 2.5M14 2.5H10M14 2.5V6.5M6 10.5L2 14.5M2 14.5H6M2 14.5L2 10.5"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
