import {
  createListSearchParams,
  ListOrder,
  ListPagination,
} from 'api/list-params'
import { pipe } from 'fp-ts/lib/function'
import { decodeJsonWithTotal, get, put } from 'lib/request'

import { TCompanyCharge, TCompanyInvoice } from './billing.codecs'

export type UpdateBillingAddressForCompanyInput = {
  body: {
    email: string
    country: string
    city: string
    suite: string
    postal: string
    vat: string
    state: string
  }
}

const replaceEmptyStringsWithNull = (obj: Record<string, any>) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) =>
      value === '' ? [key, null] : [key, value],
    ),
  )
}

export const updateBillingAddressForCompany = async ({
  body,
}: UpdateBillingAddressForCompanyInput) => {
  return await put('subscription/subscriptions/companies/me/addresses', {
    body: replaceEmptyStringsWithNull(body),
  })
}

export type GetCompanyChargesListInput = {
  pagination: ListPagination
  order: ListOrder
}

export const getCompanyChargesList = async ({
  order,
  pagination,
}: GetCompanyChargesListInput) => {
  const params = createListSearchParams({
    pagination,
    order,
  })

  return pipe(
    await get('subscription/companies/me/charges/transactions', {
      query: params,
    }),
    decodeJsonWithTotal(TCompanyCharge, pagination),
  )
}

export type GetCompanyInvoicesListInput = {
  pagination: ListPagination
  order: ListOrder
}

export const getCompanyInvoicesList = async ({
  order,
  pagination,
}: GetCompanyInvoicesListInput) => {
  const params = createListSearchParams({
    pagination,
    order,
  })

  return pipe(
    await get('subscription/companies/me/invoices', {
      query: params,
    }),
    decodeJsonWithTotal(TCompanyInvoice, pagination),
  )
}
