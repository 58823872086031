import { Box, Stack, Typography } from '@mui/material'
import { useGetNameWithStatus } from 'lib/app-helpers'
import { Avatar } from 'ui/data'

type Props = {
  fullName: string
  role?: string
  avatar?: string
  isActive?: boolean
}

export const UserProfile = (props: Props) => {
  const { getNameWithStatus } = useGetNameWithStatus()

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={1}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <Avatar src={props.avatar} size={24} alt={props.fullName} />
        <Typography variant="body2" fontWeight={500} noWrap>
          {getNameWithStatus(props.fullName, props.isActive)}
        </Typography>
      </Stack>

      {props.role && (
        <Typography variant="body2" color="text.secondary">
          {props.role}
        </Typography>
      )}
    </Box>
  )
}
