import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCircleArrowTop = ({ ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 32 32"
      sx={{
        ...props.sx,
      }}
    >
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill="currentColor"
      />
      <path
        d="M16 22.6663V9.33301M16 9.33301L11 14.333M16 9.33301L21 14.333"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
