import { useMutation } from '@tanstack/react-query'
import { ContactCommentsItem, editContactComment } from 'api'
import { useCallback } from 'react'

import { CommentForm } from './comment-form'

type Props = {
  comment: ContactCommentsItem
  onCancel: () => void
  onEdit: () => void
}

export const EditCommentForm = ({ comment, onCancel, onEdit }: Props) => {
  const $editContactComment = useMutation(editContactComment)

  const editComment = useCallback(
    ({ message }: { message: string }) => {
      $editContactComment.mutate(
        {
          commentId: comment.id,
          message,
        },
        {
          onSuccess: () => {
            onEdit()
          },
        },
      )
    },
    [$editContactComment, comment, onEdit],
  )

  return (
    <CommentForm
      message={comment.message}
      onSubmit={editComment}
      onCancel={onCancel}
    />
  )
}
