import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

import { TRole } from '../roles'

const TUserStatus = t.union([
  t.literal('INVITED'),
  t.literal('ACTIVE'),
  t.literal('INACTIVE'),
])

export type UserStatus = t.TypeOf<typeof TUserStatus>

export const TUserRowCount = t.strict({
  count: t.number,
})

export const TUserRow = t.intersection([
  t.strict({
    userId: t.string,
    email: t.string,
    fullName: t.string,
    role: TRole,
    status: TUserStatus,
    createdAt: DateFromISOString,
  }),
  t.partial({
    avatar: t.string,
    registeredAt: DateFromISOString,
    invitationLinkExpired: t.boolean, //not undefined if user status is INVITED
  }),
])

export type UserRow = t.TypeOf<typeof TUserRow>

export const TTimeFormat = t.union([
  t.literal('TWELVE_HOURS'),
  t.literal('TWENTY_FOUR_HOURS'),
])

export type TimeFormat = t.TypeOf<typeof TTimeFormat>

export const TDateFormat = t.union([
  t.literal('YYYY_MM_DD'),
  t.literal('MM_DD_YYYY'),
  t.literal('DD_MM_YYYY'),
  t.literal('DD_MON_YYYY'),
  t.literal('MON_DD_YYYY'),
])

export type DateFormat = t.TypeOf<typeof TDateFormat>

export const TUser = t.intersection([
  TUserRow,
  t.strict({
    countryCode: t.string,
    languageCode: t.string,
    timeZone: t.string,
    timeFormat: TTimeFormat,
    dateFormat: TDateFormat,
  }),
  t.partial({
    avatar: t.string,
    avatarId: t.string,
  }),
])

export type User = t.TypeOf<typeof TUser>

const TCalendarIntegration = t.strict({
  integrationId: t.string,
  type: t.literal('CALENDAR'),
  active: t.boolean,
  email: t.string,
})

export type CalendarIntegration = t.TypeOf<typeof TCalendarIntegration>

export const TUserIntegrations = t.array(TCalendarIntegration)

export const TCalendarOAuth2Url = t.strict({
  url: t.string,
})
