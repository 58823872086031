import { Box, Stack, Typography } from '@mui/material'
import { ContactCommentsItem } from 'api'
import { differenceInHours, formatDistanceToNow } from 'date-fns'
import { useGetNameWithStatus } from 'lib/app-helpers'
import { useDateAndTimeFormat } from 'lib/context'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { Avatar } from 'ui/data'

import { CommentActionsButton } from './comment-actions-button'

type Props = {
  comment: ContactCommentsItem
  isLast: boolean
  isMyComment: boolean
  refetch: () => void
  onLastVisible?: () => void
  onOpenEditionForm?: () => void
}

export const Comment = ({
  comment,
  isLast,
  isMyComment,
  onLastVisible,
  onOpenEditionForm,
  refetch,
}: Props) => {
  const { ref, inView } = useInView({
    skip: !isLast,
    triggerOnce: true,
    fallbackInView: true,
  })

  const { formatDate } = useDateAndTimeFormat()

  const getCommentDate = (date: Date) => {
    return differenceInHours(date, new Date()) < 24
      ? formatDistanceToNow(date, { addSuffix: true })
      : formatDate(date)
  }

  useEffect(() => {
    if (inView && onLastVisible && isLast) {
      onLastVisible()
    }
  }, [inView, onLastVisible, isLast])

  const { getNameWithStatus } = useGetNameWithStatus()

  return (
    <Box
      sx={{ padding: isMyComment ? '0px 0px 8px 32px' : '0px 32px 8px 0px' }}
    >
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          mb="6px"
          ref={ref}
        >
          <Stack direction="row" gap={1}>
            <Avatar
              src={comment.creatorProfile.avatar}
              size={28}
              alt={comment.creatorProfile.fullName}
            />

            <Box>
              <Typography variant="body2" fontWeight={500}>
                {getNameWithStatus(
                  comment.creatorProfile.fullName,
                  comment.creatorProfile.active,
                )}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {comment.creatorProfile.role}
              </Typography>
            </Box>
          </Stack>

          <Box ml="12px" display="flex">
            <Typography
              variant="caption"
              color="text.secondary"
              textAlign="right"
            >
              {getCommentDate(comment.createdAt)}
            </Typography>

            <CommentActionsButton
              comment={comment}
              onDelete={refetch}
              onOpenEditionForm={onOpenEditionForm}
            />
          </Box>
        </Stack>
      </Box>

      <Box
        sx={{
          background: theme => theme.palette.mineShaft[100],
          borderRadius: '12px',
          borderBottomLeftRadius: isMyComment ? '12px' : '0px',
          borderBottomRightRadius: isMyComment ? '0px' : '12px',
          padding: '6px 8px',
        }}
      >
        <Typography>{comment.message}</Typography>
      </Box>
    </Box>
  )
}
