import { addMinutes, isValid, parse, startOfDay } from 'date-fns'
import {
  convertTimeFormat,
  getTimeFormatPlaceholder,
  useDateAndTimeFormat,
} from 'lib/context'
import { useMemo, useState } from 'react'

import { Autocomplete, AutocompleteProps } from './autocomplete'

type Props = Omit<
  AutocompleteProps,
  'value' | 'onChange' | 'options' | 'renderInput'
> & {
  value: Date | null
  onChange: (date: Date | null) => void
  minutesStep: number
  startAfter?: Date
  date?: Date | null
}

/** Allows to choose predefined time values or to enter it manually */
export const TimePicker = ({
  value,
  minutesStep,
  startAfter,
  onChange,
  date,
  ...rest
}: Props) => {
  const { formatTime, timeFormat } = useDateAndTimeFormat()

  const [inputValue, setInputValue] = useState(() => {
    return value !== null && isValid(value) ? formatTime(value) : ''
  })

  const options = useMemo(() => {
    const minutesInDay = 24 * 60
    const count = minutesInDay / minutesStep
    const today = startOfDay(date ?? new Date())

    const times: Array<{ value: string; label: string }> = []

    for (let index = 0; index < count; index++) {
      const time = addMinutes(today, index * minutesStep)
      const formattedTime = formatTime(time)
      times.push({
        value: formattedTime,
        label: formattedTime,
      })
    }

    if (startAfter) {
      console.log('startAfter', startAfter)
      console.log(
        'filtered times',
        times.filter(time => {
          const timeDate = parse(
            time.value,
            convertTimeFormat(timeFormat),
            today,
          )
          return isValid(timeDate) && timeDate > startAfter
        }),
      )
      return times.filter(time => {
        const timeDate = parse(time.value, convertTimeFormat(timeFormat), today)
        return isValid(timeDate) && timeDate > startAfter
      })
    }

    return times
  }, [date, formatTime, minutesStep, startAfter, timeFormat])

  return (
    <Autocomplete
      value={inputValue}
      doStartWithFilter
      onChange={newValue => {
        setInputValue(newValue)
        if (newValue === '') {
          onChange(null)
        } else {
          onChange(
            parse(newValue, convertTimeFormat(timeFormat), date ?? new Date()),
          )
        }
      }}
      options={options}
      placeholder={getTimeFormatPlaceholder()}
      disableAlphabeticalOrder
      {...rest}
    />
  )
}
