import { Box, Stack, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getJobDetails, qk } from 'api'
import { paths } from 'app/routes'
import { useScopes } from 'app/scopes'
import {
  IconLocation,
  IconOfficeOutline,
  IconProjectOutline,
  IconUser,
} from 'assets/icons'
import { IconCalendarCheck02, IconCalendarPlus02 } from 'assets/icons'
import { IconBlock } from 'components/common'
import { PreviewNavigation } from 'components/global'
import { SkillBadge } from 'components/skills'
import { useFormatLocation, useGetNameWithStatus } from 'lib/app-helpers'
import { useDateAndTimeFormat } from 'lib/context'
import { renderQueryResult } from 'lib/react-query-utils'
import { jobPriorities } from 'lib/records'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { PurpleFadingGradientContainer } from 'ui/containers'
import { Avatar, BadgeCollection } from 'ui/data'
import { CloseIconButton } from 'ui/inputs/close-icon-button'
import { Link } from 'ui/navigation/link'

import { JobStatus } from './job-status'

type Props = Readonly<{
  jobId: string
  jobIds: Array<string>
  setPreviewId: (previewId: string | null) => void
}>

export const JobPreview = ({ jobId, jobIds, setPreviewId }: Props) => {
  const { t } = useTranslation()
  const { canViewJobs } = useScopes()

  const formatLocation = useFormatLocation()
  const { formatDate } = useDateAndTimeFormat()
  const { getNameWithStatus } = useGetNameWithStatus()

  const $jobDetails = useQuery(qk.jobs.details.toKeyWithArgs({ jobId }), () =>
    getJobDetails({ jobId }),
  )

  return (
    <>
      {renderQueryResult($jobDetails, {
        success: jobDetails => {
          const recruiter = jobDetails.recruiters[0]

          const location = formatLocation(
            jobDetails.address?.country?.code,
            jobDetails.address?.city,
          )

          const priority = jobPriorities[jobDetails.priority]

          const ICON_BLOCK_WIDTH = 'calc(50% - 12px)'

          return (
            <Box display="flex" flexDirection="column" height="100%">
              <PurpleFadingGradientContainer>
                <Stack spacing={2} p={2}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    {jobIds && setPreviewId && (
                      <PreviewNavigation
                        itemIds={jobIds}
                        itemId={jobId}
                        setPreviewId={setPreviewId}
                      />
                    )}

                    <Box>
                      <CloseIconButton onClose={() => setPreviewId(null)} />
                    </Box>
                  </Stack>

                  <Stack direction="row" alignItems="center" spacing={1}>
                    <JobStatus
                      jobStatusCode={jobDetails.status}
                      iconSx={{
                        fontSize: '40px',
                      }}
                    />

                    <Stack spacing={1}>
                      {canViewJobs ? (
                        <Link
                          to={generatePath(paths.jobDetails, {
                            id: jobDetails.jobId,
                          })}
                          variant="h3"
                        >
                          {jobDetails.position.name}
                        </Link>
                      ) : (
                        <Typography variant="h3">
                          {jobDetails.position.name}
                        </Typography>
                      )}

                      <Typography
                        variant="body1"
                        sx={{ color: theme => theme.palette.mineShaft[600] }}
                      >
                        {jobDetails.jobType.name} | {jobDetails.model.name}
                      </Typography>
                    </Stack>
                  </Stack>

                  {jobDetails.skills && (
                    <BadgeCollection>
                      {jobDetails.skills.map(skill => (
                        <SkillBadge
                          key={skill.skillId}
                          skillName={skill.skillName}
                          skillLevelCode={skill.skillLevelCode}
                        />
                      ))}
                    </BadgeCollection>
                  )}
                </Stack>
              </PurpleFadingGradientContainer>

              <Stack direction="row" flexWrap="wrap" gap={3} p={2}>
                <IconBlock
                  label={t('common.recruiter')}
                  text={
                    recruiter
                      ? getNameWithStatus(recruiter.fullName, recruiter.active)
                      : undefined
                  }
                  icon={
                    recruiter?.avatar ? (
                      <Avatar src={recruiter.avatar} />
                    ) : (
                      <IconUser />
                    )
                  }
                  width={ICON_BLOCK_WIDTH}
                  isTextTruncateWithTooltip
                  noWrap
                />

                <IconBlock
                  label={t('common.target_date')}
                  text={formatDate(jobDetails.targetDate)}
                  icon={<IconCalendarCheck02 />}
                  width={ICON_BLOCK_WIDTH}
                  isTextTruncateWithTooltip
                  noWrap
                />

                <IconBlock
                  label={`${t('common.department')}:`}
                  icon={<IconOfficeOutline />}
                  text={jobDetails.department.name}
                  width={ICON_BLOCK_WIDTH}
                  isTextTruncateWithTooltip
                  noWrap
                />

                <IconBlock
                  label={t('common.office')}
                  text={jobDetails.office.name}
                  icon={<IconOfficeOutline />}
                  width={ICON_BLOCK_WIDTH}
                  isTextTruncateWithTooltip
                  noWrap
                />

                <IconBlock
                  label={t('common.priority')}
                  text={t(`jobs.priorities.${jobDetails.priority}`)}
                  icon={<priority.Icon />}
                  width={ICON_BLOCK_WIDTH}
                  isTextTruncateWithTooltip
                  noWrap
                />

                {location && (
                  <IconBlock
                    label={t('common.location') + ':'}
                    icon={<IconLocation />}
                    text={location}
                    width={ICON_BLOCK_WIDTH}
                    isTextTruncateWithTooltip
                    noWrap
                  />
                )}

                {jobDetails.project && (
                  <IconBlock
                    label={t('common.project')}
                    text={jobDetails.project.name}
                    icon={<IconProjectOutline />}
                    width={ICON_BLOCK_WIDTH}
                    isTextTruncateWithTooltip
                    noWrap
                  />
                )}

                {jobDetails.project && (
                  <IconBlock
                    label={t('common.creation_date')}
                    text={formatDate(jobDetails.createdAt)}
                    icon={<IconCalendarPlus02 />}
                    width={ICON_BLOCK_WIDTH}
                    isTextTruncateWithTooltip
                    noWrap
                  />
                )}
              </Stack>
            </Box>
          )
        },
      })}
    </>
  )
}
