/* eslint-disable sonarjs/no-duplicate-string */
import { SvgIconProps } from '@mui/material'
import {
  ContactCompletenessStatuses,
  ContactHistoryTypes,
  ContactInformationType,
  ContactMatchingStatus,
} from 'api'
import { ThemeColor } from 'app/mui-theme'
import { IconChannel } from 'assets/icons'
import {
  IconCheckVerified01,
  IconMail02,
  IconPhone,
  IconXCircle,
} from 'assets/icons'
import { TFuncKey } from 'react-i18next'

export const contactInformationTypes: Record<
  ContactInformationType,
  {
    Icon: (props: SvgIconProps<'svg', {}>) => JSX.Element
  }
> = {
  EMAIL: { Icon: IconMail02 },
  PHONE: { Icon: IconPhone },
  MESSENGER: { Icon: IconChannel },
}

type ContactLabels = 'WORK' | 'PERSONAL'

export const contactInformationLabels: Array<ContactLabels> = [
  'WORK',
  'PERSONAL',
]

export const completenessStatuses: Record<
  ContactCompletenessStatuses,
  {
    tooltipText: TFuncKey
    background: ThemeColor
    color: ThemeColor
    Icon: (props: SvgIconProps<'svg', {}>) => JSX.Element
  }
> = {
  INCOMPLETE: {
    tooltipText: 'contacts.contacts_completeness.add_missing_information',
    background: '#FEE6E7',
    color: '#F1010A',
    Icon: IconXCircle,
  },
  MINIMAL: {
    tooltipText: 'contacts.contacts_completeness.add_missing_information',
    background: '#FFF3E6',
    color: 'flushOrange.main',
    Icon: IconXCircle,
  },
  ACCEPTABLE: {
    tooltipText: 'contacts.contacts_completeness.add_missing_fields',
    background: '#FFF6E6',
    color: '#FEA800',
    Icon: IconXCircle,
  },
  COMPLETE: {
    tooltipText: 'contacts.contacts_completeness.fully_completed',
    background: 'jewel.main',
    color: 'white',
    Icon: IconCheckVerified01,
  },
}

export const contactHistoryTypes: Record<ContactHistoryTypes, ThemeColor> = {
  CONTACT_ACTIVITY_STATUS_CHANGED: '#FFF6E6',
  CONTACT_ACTIVITY_CHANGED: '#FFF6E6',
  CONTACT_ACTIVITY_CREATED: '#FFF6E6',
  CONTACT_CREATED: '#FFF6E6',
  CONTACT_REMOVED: '#FEE6E7',
  CONTACT_ADDED_TALENT_POOL: '#E6F4F1',
  CONTACT_REMOVED_TALENT_POOL: '#FEE6E7',
  CONTACT_APPLIED_JOB: '#E6F4F1',
  CONTACT_MOVED_JOB: '#E6F3FE',
  CONTACT_REJECTED_JOB: '#FEE6E7',
  CONTACT_RESTORED_JOB: '#E6ECFD',
  CONTACT_HIRED_JOB: '#F1EBFD',
  CONTACT_UPDATED: '#FFF6E6',
  CONTACT_DOCUMENT_ADDED: '#E6F4F1',
  CONTACT_DOCUMENT_REMOVED: '#FEE6E7',
  CONTACT_INVITATION_CREATED: '#E6F3FE',
  CONTACT_CREATED_FROM_PUBLIC_JOB: '#FFF6E6',
  CONTACT_DOCUMENT_ADDED_FROM_PUBLIC_JOB: '#E6F4F1',
  CONTACT_APPLIED_PUBLIC_JOB: '#E6F3FE',
  CONTACT_ACTIVITY_CREATED_MANUALLY: '#F4FAFF',
  CONTACT_ACTIVITY_CREATED_AUTOMATICALLY: '#F4FAFF',
  CONTACT_JOB_ARCHIVED: '#E6ECFD',
}

export const contactMatchingStatus: Record<ContactMatchingStatus, ThemeColor> =
  {
    MISMATCH: '#FEE6E7',
    ALMOST_MATCH: '#FFF3E6',
    MATCH: '#FFF6E6',
    BEST_MATCH: '#E6F4F1',
  }
