import { useQuery } from '@tanstack/react-query'
import { getEmailIntegration, qk } from 'api'
import { paths } from 'app/paths'
import { useScopes } from 'app/scopes'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'ui/navigation'

export const useCheckEmailIntegration = ({
  isShortExpiredHint,
}: {
  isShortExpiredHint?: boolean
}) => {
  const { canViewEmailIntegration, canManageCompanyIntegration } = useScopes()
  const $emailIntegration = useQuery(
    qk.company.emailIntegration.toKey(),
    getEmailIntegration,
    { enabled: canViewEmailIntegration },
  )
  const { t } = useTranslation()

  const integratedEmail = $emailIntegration.data?.find(
    integration => integration.type === 'EMAIL',
  )
  const isEmailIntegrated = Boolean(integratedEmail?.active)

  let expiredHint
  if (integratedEmail && integratedEmail.expired) {
    if (isShortExpiredHint) {
      expiredHint = canManageCompanyIntegration ? (
        <Trans
          i18nKey="hints.gmail_integration_expired_with_reconnect_link_short"
          components={{
            reconnectLink: (
              <Link
                to={paths.settingsCompanyIntegrations}
                underline="always"
                sx={{ fontWeight: '500' }}
              />
            ),
          }}
        />
      ) : (
        t('hints.gmail_integration_expired_without_reconnect_link_short')
      )
    } else {
      expiredHint = canManageCompanyIntegration ? (
        <Trans
          i18nKey="hints.gmail_integration_expired_with_reconnect_link"
          components={{
            reconnectLink: (
              <Link
                to={paths.settingsCompanyIntegrations}
                underline="always"
                sx={{ fontWeight: '500' }}
              />
            ),
          }}
        />
      ) : (
        t('hints.gmail_integration_expired_without_reconnect_link')
      )
    }
  }

  return {
    isEmailIntegrated,
    isLoadingIntegration: $emailIntegration.isLoading,
    integratedEmail,
    refetchIntegration: $emailIntegration.refetch,
    isRefetchingIntegration: $emailIntegration.isRefetching,
    expiredHint,
  }
}
