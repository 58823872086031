import { TDateFormat, TTimeFormat } from 'api/auth'
import { TStartWeekDay } from 'api/common'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

export const TCompanyDetails = t.intersection([
  t.strict({
    companyId: t.string,
    name: t.string,
    languageResponse: t.strict({
      name: t.string,
      code: t.string,
    }),
    countryResponse: t.strict({
      name: t.string,
      code: t.string,
    }),
    zoneId: t.string,
    startWeekDay: TStartWeekDay,
    createdAt: DateFromISOString,
    packageConfigured: t.boolean,
    usersCount: t.number,
    timeFormat: TTimeFormat,
    dateFormat: TDateFormat,
  }),
  t.partial({
    teamMembers: t.strict({
      code: t.string,
      name: t.string,
    }),
    industry: t.strict({
      code: t.string,
      name: t.string,
    }),
    logoUri: t.string,
    logoId: t.string,
  }),
])

export type CompanyDetails = t.TypeOf<typeof TCompanyDetails>

export const TCompanyName = t.strict({
  companyId: t.string,
  name: t.string,
})

export const TCompanyOffice = t.strict({
  name: t.string,
  officeId: t.string,
})

export type CompanyOffice = t.TypeOf<typeof TCompanyOffice>

export const TCompanyOfficesList = t.array(TCompanyOffice)

export const TCompanyProject = t.intersection([
  t.strict({
    name: t.string,
    projectId: t.string,
  }),
  t.partial({
    closed: t.boolean,
  }),
])

export type CompanyProject = t.TypeOf<typeof TCompanyProject>

export const TCompanyProjectsList = t.array(TCompanyProject)

export const TCompanyPosition = t.strict({
  positionId: t.string,
  name: t.string,
})

export type CompanyPosition = t.TypeOf<typeof TCompanyPosition>

export const TCompanyPositionsList = t.array(TCompanyPosition)

export const TCompanyDepartment = t.strict({
  departmentId: t.string,
  name: t.string,
})

export type CompanyDepartment = t.TypeOf<typeof TCompanyDepartment>

export const TCompanyDepartmentsList = t.array(TCompanyDepartment)

const TCompanyLanguage = t.strict({
  name: t.string,
  id: t.string,
})

export type CompanyLanguage = t.TypeOf<typeof TCompanyLanguage>

export const TCompanyLanguagesList = t.array(TCompanyLanguage)

export const TCompanyJobType = t.strict({
  jobTypeId: t.string,
  name: t.string,
})

export type CompanyJobType = t.TypeOf<typeof TCompanyJobType>

export const TCompanyJobTypesList = t.array(TCompanyJobType)

export const TCompanyWorkExperience = t.strict({
  workExperienceId: t.string,
  name: t.string,
})

export type CompanyWorkExperience = t.TypeOf<typeof TCompanyWorkExperience>

export const TCompanyWorkExperiencesList = t.array(TCompanyWorkExperience)

const TCompanyRejectReason = t.strict({
  name: t.string,
  rejectReasonId: t.string,
})

export type CompanyRejectReason = t.TypeOf<typeof TCompanyRejectReason>

export const TCompanyRejectReasonsList = t.array(TCompanyRejectReason)
