import { Box, Stack, SvgIconProps, Typography } from '@mui/material'
import { IconSettings04 } from 'assets/icons'
import { ReactNode } from 'react'
import { Trans } from 'react-i18next'

type Props = Readonly<{
  count: number
  Icon: (props: SvgIconProps<'svg', {}>) => JSX.Element
  totalCount?: number
  isFiltersApplied?: boolean
  isDisplayFilterText?: boolean
  renderFilterText?: RenderCountText
  renderTotalText?: RenderCountText
}>

type RenderCountText = ({
  boltTextComponent,
  count,
}: {
  boltTextComponent: JSX.Element
  count?: number
}) => ReactNode

export const TableTotalCount = ({
  count,
  Icon,
  totalCount,
  isFiltersApplied,
  isDisplayFilterText = true,
  renderFilterText,
  renderTotalText,
}: Props) => {
  const valueWrapper = <Typography component="span" variant="h4" />

  return (
    <Stack direction="row" spacing={2} py={0.5}>
      {isFiltersApplied && totalCount !== undefined && (
        <Box display="flex" alignItems="center">
          <IconSettings04 sx={{ fontSize: '16px' }} />
          <Typography variant="caption" ml={0.75}>
            {renderFilterText ? (
              renderFilterText({ boltTextComponent: valueWrapper, count })
            ) : (
              <Trans
                i18nKey="common.table_total_count.count_according_to_filters"
                values={{ value: count }}
                components={{ valueWrapper }}
              />
            )}
          </Typography>
        </Box>
      )}

      {isDisplayFilterText && (
        <Box display="flex" alignItems="center">
          <Icon sx={{ fontSize: '16px' }} />
          <Typography ml={0.75} variant="caption">
            {renderTotalText ? (
              renderTotalText({
                boltTextComponent: valueWrapper,
                count: totalCount,
              })
            ) : (
              <Trans
                i18nKey="common.table_total_count.total_count"
                values={{ value: totalCount ?? count }}
                components={{ valueWrapper }}
              />
            )}
          </Typography>
        </Box>
      )}
    </Stack>
  )
}
