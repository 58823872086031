import { Box } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { addContactComment } from 'api'
import { useCallback } from 'react'

import { CommentForm } from './comment-form'
type Props = {
  contactId: string
  refetch: () => void
}

export const AddCommentForm = ({ contactId, refetch }: Props) => {
  const $addContactComment = useMutation(addContactComment)

  const addComment = useCallback(
    ({ message }: { message: string }) => {
      $addContactComment.mutate(
        {
          profileId: contactId,
          message,
        },
        {
          onSuccess: () => {
            refetch()
          },
        },
      )
    },
    [$addContactComment, contactId, refetch],
  )

  return (
    <Box>
      <CommentForm message="" onSubmit={addComment} isAdding />
    </Box>
  )
}
