import { InputAdornment, Stack, SxProps } from '@mui/material'
import { DesktopDatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import { IconCalendar, IconX } from 'assets/icons'
import { isValid } from 'date-fns/fp'
import { convertDateFormat, useDateAndTimeFormat } from 'lib/context'
import { useBoolean } from 'lib/react-utils'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, FormControlWrapper, InputBase } from 'ui/inputs/common'
import { IconButton } from 'ui/inputs/icon-button'

import { CalendarPicker, CalendarPickerOptions } from './calendar-picker'

export type Props = FormControlWrapper & {
  value: Date | null
  name: string
  onChange: (date: Date | null) => void

  inputBaseProps?: Partial<{
    isSmall: boolean
    isMinWidth: boolean
    sx: SxProps
    dataCy: string
    withoutIcon: boolean
    disabled: boolean
  }>

  calendarOptions?: CalendarPickerOptions
}

export const DatePicker = ({
  value,
  onChange,
  inputBaseProps,
  calendarOptions,
  label,
  error,
  helperText,
  required,
  name,
}: Props) => {
  const { t } = useTranslation()

  const invalid = useBoolean(false)
  const isCalendarOpen = useBoolean(false)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const { dateFormat } = useDateAndTimeFormat()

  const handleDateChange = (newDate: Date | null) => {
    if (newDate === null || isValid(newDate)) {
      invalid.setFalse()
    } else {
      invalid.setTrue()
    }

    onChange(newDate)
  }

  return (
    <>
      <FormControl
        label={label}
        required={required}
        error={invalid.isTrue || error}
        helperText={invalid.isTrue ? t('validations.invalid_date') : helperText}
        name={name}
      >
        <MuiDatePicker
          disableOpenPicker
          value={value}
          inputFormat={convertDateFormat(dateFormat)}
          onChange={handleDateChange}
          renderInput={params => (
            <InputBase
              {...params.InputProps}
              inputProps={{
                ...params.inputProps,
                'data-cy': inputBaseProps?.dataCy,
              }}
              ref={inputRef}
              id={label}
              small={inputBaseProps?.isSmall}
              disabled={inputBaseProps?.disabled}
              sx={{
                width: inputBaseProps?.isMinWidth ? '240px' : undefined,
                ...inputBaseProps?.sx,
              }}
              error={invalid.isTrue || error}
              onClick={() => {
                if (inputBaseProps?.withoutIcon && !inputBaseProps?.disabled) {
                  isCalendarOpen.setTrue()
                }
              }}
              endAdornment={
                !inputBaseProps?.withoutIcon && !inputBaseProps?.disabled ? (
                  <InputAdornment position="end">
                    <Stack direction="row" spacing={0.25} alignItems="center">
                      {value && (
                        <IconButton onClick={() => onChange(null)}>
                          <IconX sx={{ fontSize: '16px' }} />
                        </IconButton>
                      )}

                      <IconButton onClick={isCalendarOpen.setTrue}>
                        <IconCalendar sx={{ fontSize: '16px' }} />
                      </IconButton>
                    </Stack>
                  </InputAdornment>
                ) : undefined
              }
            />
          )}
        />
      </FormControl>

      <CalendarPicker
        isOpen={isCalendarOpen.isTrue}
        onClose={isCalendarOpen.setFalse}
        anchorElement={inputRef.current}
        value={value}
        onChange={onChange}
        options={calendarOptions}
        texts={{
          title: label,
        }}
      />
    </>
  )
}
