import { Box, Typography } from '@mui/material'
import { Notification } from 'api'
import { previewCloseExceptionClass } from 'components/common'
import { NotificationStatusButton } from 'components/notifications'
import { useDateAndTimeFormat } from 'lib/context'
import { notificationCriticalities } from 'lib/records'
import { Link } from 'ui/navigation/link'

type Props = {
  notification: Notification
  onClickLink: () => void
}

export const NotificationItem = ({ notification, onClickLink }: Props) => {
  const { formatDate, formatTime } = useDateAndTimeFormat()

  const { Icon: CriticalityIcon } =
    notificationCriticalities[notification.criticality]

  const item = (
    <Box padding="8px 12px">
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        mb={1}
      >
        <Box display="flex" alignItems="center" mr={4} minHeight="32px">
          <CriticalityIcon sx={{ fontSize: '16px' }} />
          <Typography variant="h4" ml="8px">
            {notification.subject}
          </Typography>
        </Box>

        <Box
          onClick={event => {
            event.stopPropagation()
            event.preventDefault()
          }}
        >
          <NotificationStatusButton notification={notification} hideOnRead />
        </Box>
      </Box>

      <Typography variant="h6" mb={1}>
        {notification.message}
      </Typography>

      <Box display="flex" alignItems="center">
        <Typography variant="caption" color="mineShaft[800]" mr={1}>
          {formatDate(notification.createdAt)}
        </Typography>

        <Typography variant="caption" color="mineShaft[800]" ml={0.5}>
          {formatTime(notification.createdAt)}
        </Typography>
      </Box>
    </Box>
  )

  if (notification.onclick) {
    const { origin } = new URL(notification.onclick)
    const path = notification.onclick.replace(origin, '')

    return (
      <Link
        to={path}
        onClick={onClickLink}
        display="block"
        // Activity notification link leads to activities page
        // with corresponding activity preview opened.
        // But when it happens from activities page directly, the preview is closed immediately
        // That is why we need to add this class to prevent closing a preview
        className={previewCloseExceptionClass}
      >
        {item}
      </Link>
    )
  }

  return item
}
