import { Box, Paper, Typography } from '@mui/material'
import { IconResize } from 'assets/icons'
import { useBoolean } from 'lib/react-utils'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from 'ui/inputs/icon-button'

type Props = {
  children: ReactNode
  commentsForm: ReactNode
}

export const CommentsSectionLayout = ({ children, commentsForm }: Props) => {
  const { t } = useTranslation()

  const showFullSize = useBoolean()

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px 20px',
        }}
      >
        <Typography variant="h5">{t('comments.section.title')}</Typography>

        <IconButton onClick={showFullSize.toggle} ml="8px">
          <IconResize sx={{ width: '16px', height: '16px' }} />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: showFullSize.isTrue ? '520px' : '260px',
          maxHeight: showFullSize.isTrue ? '520px' : '260px',
          padding: '12px',
          overflow: 'auto',
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#9A7EF0',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'white',
            borderLeft: 'none',
          },
        }}
      >
        {children}
      </Box>

      <Box
        sx={{ boxShadow: '0px -4px 6px 0px #0000001A', padding: '12px 16px' }}
      >
        {commentsForm}
      </Box>
    </Paper>
  )
}
