import { useMutation } from '@tanstack/react-query'
import { ContactCommentsItem, deleteContactComment } from 'api'
import { IconTreeDots } from 'assets/icons/icons-v2/general/three-dots'
import { useConfirmationDialog } from 'lib/app-helpers'
import { useBoolean } from 'lib/react-utils'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from 'ui/inputs/icon-button'
import { Menu, MenuItem } from 'ui/navigation'

type Props = {
  comment: ContactCommentsItem
  onDelete: () => void
  onOpenEditionForm?: () => void
}

export const CommentActionsButton = ({
  comment,
  onDelete,
  onOpenEditionForm,
}: Props) => {
  const { t } = useTranslation()

  const menuOpened = useBoolean()
  const menuAnchor = useRef<HTMLButtonElement | null>(null)

  const $deleteContactComment = useMutation(deleteContactComment)

  const deleteDialog = useConfirmationDialog<string>(commentId => {
    $deleteContactComment.mutate(
      {
        commentId,
      },
      {
        onSuccess: () => {
          onDelete()
        },
      },
    )
  })

  if (!comment.editable || !comment.deletable) {
    return null
  }

  return (
    <>
      <IconButton
        sx={{ padding: '2px', width: '20px', height: '20px' }}
        ref={menuAnchor}
        onClick={menuOpened.toggle}
      >
        <IconTreeDots sx={{ fontSize: '16px' }} />
      </IconButton>

      <Menu
        anchorEl={menuAnchor.current}
        isOpen={menuOpened.isTrue}
        onClose={menuOpened.setFalse}
        placement="bottom-end"
        minWidth={100}
        disablePortal={false}
      >
        {comment.editable && (
          <MenuItem
            divider
            onClick={() => {
              onOpenEditionForm?.()
              menuOpened.setFalse()
            }}
          >
            {t('common.edit')}
          </MenuItem>
        )}

        {comment.deletable && (
          <MenuItem
            divider
            onClick={() => {
              deleteDialog.openDialog(comment.id)
              menuOpened.setFalse()
            }}
          >
            {t('common.delete')}
          </MenuItem>
        )}
      </Menu>

      {deleteDialog.renderConfirmDialog({
        size: 'small',
        variant: 'danger',
        title: t('common.delete'),
        children: t('confirms.contacts_confirm_delete_comment'),
      })}
    </>
  )
}
