import { Stack, Typography } from '@mui/material'
import { IconTime } from 'assets/icons'
import { useDateAndTimeFormat } from 'lib/context'

type Props = Readonly<{
  date: Date
}>

export const DateWithTime = ({ date }: Props) => {
  const { formatDate, formatTime } = useDateAndTimeFormat()

  return (
    <Stack direction="row" spacing={1.5}>
      <Typography variant="body2">{formatDate(date)}</Typography>

      <Stack direction="row" alignItems="center" spacing={0.5}>
        <IconTime sx={{ fontSize: 14, color: 'greyBlue.light' }} />

        <Typography variant="body2">{formatTime(date)}</Typography>
      </Stack>
    </Stack>
  )
}
