import {
  createListSearchParams,
  ListOrder,
  ListPagination,
} from 'api/list-params'
import { pipe } from 'fp-ts/function'
import {
  decodeJson,
  decodeJsonWithTotal,
  del,
  get,
  patch,
  post,
  put,
} from 'lib/request'

import { RoleId } from '../roles'
import {
  DateFormat,
  TCalendarOAuth2Url,
  TimeFormat,
  TUser,
  TUserIntegrations,
  TUserRow,
  TUserRowCount,
  UserStatus,
} from './users.codecs'

export type UserId = {
  userId: string
}

export type RecruiterId = {
  recruiterId: string
}

const USER_PROFILE_PATH = 'auth/users/:userId'

export const getMyProfile = async () => {
  return pipe(await get('auth/users/me', {}), decodeJson(TUser))
}

export type UpdateMyProfileInput = {
  fullName: string
  countryCode: string
  languageCode: string
  zoneId: string
  timeFormat: TimeFormat
  dateFormat: DateFormat
  avatarId?: string
}

export const updateMyProfile = async (input: UpdateMyProfileInput) => {
  return await put('auth/users/me', { body: input })
}

export const updateMyAvatar = async (input: { imageId: string }) => {
  return await post('auth/users/me/avatar', {
    body: {
      avatar: input.imageId,
    },
  })
}

export const removeMyAvatar = async () => {
  return await del('auth/users/me/avatar', {})
}

export type GetUsersListInput = {
  pagination: ListPagination
  roles?: ReadonlyArray<RoleId>
  statuses?: ReadonlyArray<UserStatus>
  order?: ListOrder
}

export const getUsersList = async ({
  pagination,
  roles,
  order,
  statuses,
}: GetUsersListInput) => {
  const params = createListSearchParams({ pagination, order })

  return pipe(
    await post('auth/companies/me/users', {
      query: params,
      body: {
        roles,
        userStatuses: statuses,
      },
    }),
    decodeJsonWithTotal(TUserRow, pagination),
  )
}

export const getUsersListCount = async () => {
  return pipe(
    await get('auth/companies/me/users/count', {}),
    decodeJson(TUserRowCount),
  )
}

export type GetUserDetailsInput = { userId: string }

export const getUserDetails = async ({ userId }: GetUserDetailsInput) => {
  return pipe(
    await get(USER_PROFILE_PATH, { params: { userId } }),
    decodeJson(TUser),
  )
}

export const updateUserStatus = async ({
  userId,
  active,
}: UserId & {
  active: boolean
}) => {
  return await patch(
    `subscription/subscriptions/companies/me/users/:userId/active`,
    {
      params: { userId },
      body: {
        active,
      },
    },
  )
}

export type UpdateUserProfileInput = UserId & {
  fullName: string
  languageCode: string
  countryCode: string
  roleName: string
  zoneId: string
}

export const updateUserProfile = async ({
  userId,
  ...rest
}: UpdateUserProfileInput) => {
  return await patch(USER_PROFILE_PATH, {
    params: { userId },
    body: rest,
  })
}

export const getUserIntegrations = async () => {
  return pipe(
    await get('api/users/me/integrations', {}),
    decodeJson(TUserIntegrations),
  )
}

export const getCalendarOAuth2Url = async () => {
  return pipe(
    await get('api/users/me/integrations/calendars/oauth2-url', {}),
    decodeJson(TCalendarOAuth2Url),
  )
}

export const createCalendarIntegration = async (code: string) => {
  return await post('api/users/me/integrations/calendars', {
    body: { code },
  })
}

export const removeUserIntegration = async (integrationId: string) => {
  return await del(`api/users/me/integrations/:integrationId`, {
    params: { integrationId },
  })
}
