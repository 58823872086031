import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconComments = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 64 62"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M14.1253 28.6864C13.9388 27.4847 13.842 26.2536 13.842 25C13.842 11.7452 24.6579 1 37.9999 1C51.342 1 62.1578 11.7452 62.1578 25C62.1578 27.9942 61.6059 30.8604 60.5976 33.5035C60.3882 34.0525 60.2835 34.3269 60.236 34.5412C60.1888 34.7536 60.1707 34.9029 60.1656 35.1204C60.1604 35.3398 60.1901 35.5815 60.2497 36.065L61.4574 45.8756C61.5882 46.9376 61.6535 47.4686 61.4769 47.8547C61.3221 48.1929 61.0472 48.4616 60.7054 48.6085C60.3154 48.7762 59.786 48.6986 58.7273 48.5434L49.1715 47.1427C48.6726 47.0696 48.4231 47.033 48.1959 47.0343C47.9711 47.0356 47.8156 47.0522 47.5956 47.0984C47.3733 47.1452 47.0892 47.2516 46.5211 47.4644C43.8712 48.4569 40.9994 49 37.9999 49C36.7454 49 35.5131 48.905 34.3101 48.7218M18.7369 61C27.6316 61 34.8422 53.6127 34.8422 44.5C34.8422 35.3873 27.6316 28 18.7369 28C9.84221 28 2.63164 35.3873 2.63164 44.5C2.63164 46.3318 2.92299 48.0938 3.46081 49.7402C3.68815 50.4361 3.80183 50.7841 3.83913 51.0218C3.87808 51.27 3.88491 51.4094 3.8704 51.6602C3.85651 51.9004 3.7964 52.1719 3.6762 52.7149L1.84216 61L10.8266 59.773C11.317 59.706 11.5622 59.6725 11.7763 59.674C12.0017 59.6755 12.1214 59.6877 12.3425 59.7318C12.5525 59.7737 12.8646 59.8838 13.489 60.1042C15.1339 60.6848 16.8995 61 18.7369 61Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
