import { Box, Tooltip } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { ListOrder, qk } from 'api'
import { getEmailTemplateListRequest } from 'api/email-templates'
import { LocalStorageKey } from 'app/enums'
import { useScopes } from 'app/scopes'
import { IconInfoCircleOutline } from 'assets/icons'
import { previewCloseExceptionClass, PreviewDrawer } from 'components/common'
import { PageWrapper } from 'components/settings/page-wrapper'
import { ErrorTemplate, NoDataTemplate } from 'components/templates'
import * as Arr from 'fp-ts/Array'
import { usePagination } from 'lib/app-helpers'
import { useDateAndTimeFormat } from 'lib/context'
import { renderQueryResult } from 'lib/react-query-utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table, TableSkeleton } from 'ui/data/table'
import { IconButton } from 'ui/inputs/icon-button'
import { TruncateWithTooltip } from 'ui/utils'

import { CreateEmailTemplateButton } from './create-email-template-button'
import { DeleteEmailTemplateIconButton } from './delete-email-template-icon-button'
import { EmailTemplatePreview } from './email-template-preview'
import { UpdateEmailTemplateIconButton } from './update-email-template-icon-button'

export const EMAIL_TEMPLATE_LIMIT = 50

export const EmailTemplates = () => {
  const { t } = useTranslation()

  const { canManageEmailTemplate: canManageEmailTemplateScope } = useScopes()

  const [previewId, setPreviewId] = useState<string | null>(null)

  const pagination = usePagination(LocalStorageKey.EmailTemplates)

  const [order, setOrder] = useState<ListOrder>({
    column: 'created_at',
    direction: 'desc',
  })

  const { formatDate } = useDateAndTimeFormat()

  const $emailTemplates = useQuery(
    qk.emailTemplates.list.toKeyWithArgs({ order, pagination }),
    () => getEmailTemplateListRequest({ order, pagination }),
    { keepPreviousData: true },
  )

  const emailTemplatesCount = $emailTemplates.data
    ? $emailTemplates.data.count
    : null

  const isUnderEmailTemplateLimit =
    emailTemplatesCount !== null
      ? emailTemplatesCount < EMAIL_TEMPLATE_LIMIT
      : false

  const canManageEmailTemplate =
    canManageEmailTemplateScope && isUnderEmailTemplateLimit

  return (
    <PageWrapper
      title={t('common.email_templates')}
      description={t('common.email_templates_description')}
      button={
        canManageEmailTemplate ? (
          <CreateEmailTemplateButton
            onCreate={() => $emailTemplates.refetch()}
          />
        ) : null
      }
    >
      {renderQueryResult($emailTemplates, {
        loading: () => <TableSkeleton columnsCount={5} />,
        error: error => <ErrorTemplate error={error} />,
        success: emailTemplates => {
          if (Arr.isEmpty(emailTemplates.rows)) {
            return (
              <Box display="flex" justifyContent="center">
                <NoDataTemplate title={t('common.no_templates')} />
              </Box>
            )
          }

          const rows = emailTemplates.rows.map(emailTemplate => {
            return {
              key: emailTemplate.id,
              cells: [
                <TruncateWithTooltip
                  key="emailTemplateName"
                  variant="inherit"
                  maxWidth={240}
                >
                  {emailTemplate.name}
                </TruncateWithTooltip>,
                <TruncateWithTooltip
                  key="emailTemplateSubject"
                  variant="inherit"
                  maxWidth={240}
                >
                  {emailTemplate.emailSubject}
                </TruncateWithTooltip>,
                emailTemplate.creator.fullName,
                formatDate(emailTemplate.createdAt),
                <>
                  <IconButton
                    onClick={() => {
                      setPreviewId(emailTemplate.id)
                    }}
                  >
                    <Tooltip title={t('common.view_details')}>
                      <Box>
                        <IconInfoCircleOutline
                          sx={{ fontSize: '16px', color: 'greyBlue.main' }}
                        />
                      </Box>
                    </Tooltip>
                  </IconButton>

                  {canManageEmailTemplateScope && (
                    <UpdateEmailTemplateIconButton
                      emailTemplate={emailTemplate}
                      onUpdate={() => $emailTemplates.refetch()}
                    />
                  )}

                  {canManageEmailTemplateScope && (
                    <DeleteEmailTemplateIconButton
                      emailTemplateId={emailTemplate.id}
                      emailTemplateName={emailTemplate.name}
                      onDelete={() => $emailTemplates.refetch()}
                    />
                  )}
                </>,
              ],
            }
          })

          return (
            <>
              <Table
                key={Date.now()}
                columns={[
                  { name: t('common.name'), key: 'name', sortable: true },
                  {
                    name: t('common.subject'),
                    key: 'subject',
                    sortable: true,
                  },
                  {
                    name: t('common.creator'),
                    key: 'creator_name',
                    sortable: true,
                  },
                  {
                    name: t('common.created_at'),
                    key: 'created_at',
                    sortable: true,
                  },
                  { name: t('common.actions'), key: 'actions', align: 'right' },
                ]}
                rows={rows}
                rowsClassName={previewCloseExceptionClass}
                order={order}
                setOrder={setOrder}
                pagination={pagination}
                totalCount={emailTemplates.count}
              />

              <PreviewDrawer
                onClose={() => setPreviewId(null)}
                isOpened={previewId !== null}
                clickAwayDisabled={false}
              >
                {previewId && (
                  <EmailTemplatePreview
                    previewId={previewId}
                    onCloseDialog={() => setPreviewId(null)}
                    onUpdate={() => $emailTemplates.refetch()}
                  />
                )}
              </PreviewDrawer>
            </>
          )
        },
      })}
    </PageWrapper>
  )
}
