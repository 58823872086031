import { Box, Skeleton, Stack, Tooltip, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getFile, qk } from 'api'
import { useScopes } from 'app/scopes'
import { IconFile06 } from 'assets/icons'
import { useDateAndTimeFormat } from 'lib/context'
import { useBoolean } from 'lib/react-utils'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DocumentPreviewDialog } from './document-preview-dialog'

const PDF_FILE_TYPE = 'application/pdf'

type Props = {
  fileId: string
  fileName: string
  fileCreatedAt?: Date
}

export const Document = (props: Props) => {
  const dialogOpen = useBoolean()
  const { t } = useTranslation()
  const { canViewFile } = useScopes()

  const { data } = useQuery(
    qk.files.file.toKeyWithArgs({ fileId: props.fileId }),
    () => getFile({ fileId: props.fileId }),
  )

  const { formatDate } = useDateAndTimeFormat()

  const url = data ? URL.createObjectURL(data) : undefined

  useEffect(() => {
    return () => {
      if (url) {
        URL.revokeObjectURL(url)
      }
    }
  }, [url])

  return (
    <Box>
      <Tooltip
        title={
          data && data.type === PDF_FILE_TYPE
            ? t('common.open_preview')
            : t('common.download')
        }
        arrow
        placement="top"
      >
        <Stack
          direction="row"
          justifyContent="space-around"
          alignContent="center"
          p={2}
          width="fit-content"
          maxWidth="100%"
          sx={{
            border: theme => `1px solid ${theme.palette.mineShaft[100]}`,
            borderRadius: '5px',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (url) {
              const anchor = document.createElement('a')
              anchor.href = url
              anchor.download = props.fileName
              anchor.click()
              URL.revokeObjectURL(url)
            }
          }}
          {...(data &&
            canViewFile &&
            data.type === PDF_FILE_TYPE && {
              onClick: dialogOpen.setTrue,
            })}
        >
          <IconFile06 color="primary" />

          <Stack direction="row" alignItems="center" spacing={2} ml={1}>
            {url ? (
              <>
                <Typography maxWidth={400} sx={{ wordWrap: 'break-word' }}>
                  {props.fileName}
                </Typography>
                {props.fileCreatedAt && (
                  <Typography variant="body2">
                    {formatDate(props.fileCreatedAt)}
                  </Typography>
                )}
              </>
            ) : (
              <>
                <Skeleton width={75} sx={{ mx: 1 }} />{' '}
                <Skeleton width={25} sx={{ mx: 1 }} />
              </>
            )}
          </Stack>
        </Stack>
      </Tooltip>

      {url && data && (
        <DocumentPreviewDialog
          isOpen={dialogOpen.isTrue}
          onClose={dialogOpen.setFalse}
          url={url}
          name={props.fileName}
        />
      )}
    </Box>
  )
}
