import { format } from 'date-fns/fp'

// If you need to format date according to the configured user time zone or UTC
// see `useDateAndTimeFormat` hook from app-helpers
// If you just need to format the date to some pattern in local time zone, use these utilities
export const formatMonth = format('MMM')
export const formatMonthYear = format('MMM yyyy')

export const toServerDate = format('yyyy-MM-dd')
/**
 * Use it when you need to send date to the server
 * without transformation from local timezone to UTC (rare case)
 * For example
 * Given date: Thu Sep 15 2022 16:56:37 GMT+0300 (Eastern European Summer Time)
 * Result: '2022-09-15T16:56:37'
 */
export const toServerDateTime = format("yyyy-MM-dd'T'HH:mm:ss")
