import { DateFormat, TimeFormat } from 'api'
import constate from 'constate'
import { getDefaultOptions } from 'date-fns'
import { formatInTimeZone, OptionsWithTZ } from 'date-fns-tz'
import { useMyTimeZone } from 'lib/app-helpers'

type UseDateAndTimeFormatInput = {
  timeFormat: TimeFormat
  dateFormat: DateFormat
}

const useDateAndTimeFormatBase = ({
  timeFormat,
  dateFormat,
}: UseDateAndTimeFormatInput) => {
  const myTimeZone = useMyTimeZone()
  const dateFnsDateFormat = convertDateFormat(dateFormat)
  const dateFnsTimeFormat = convertTimeFormat(timeFormat)

  const formatWithTimeZone = (pattern: string) => {
    return (date: Date, options?: OptionsWithTZ) => {
      return formatInTimeZone(date, options?.timeZone ?? myTimeZone, pattern, {
        ...getDefaultOptions(),
        ...options,
      })
    }
  }

  const formatDate = formatWithTimeZone(dateFnsDateFormat)
  const formatTime = formatWithTimeZone(dateFnsTimeFormat)
  const formatDateAndTime = formatWithTimeZone(
    `${dateFnsDateFormat} ${dateFnsTimeFormat}`,
  )

  return {
    formatDate,
    formatTime,
    formatDateAndTime,
    timeFormat,
    dateFormat,
  } as const
}

export const convertDateFormat = (format: DateFormat) => {
  switch (format) {
    case 'YYYY_MM_DD': {
      return 'yyyy-MM-dd'
    }
    case 'MM_DD_YYYY': {
      return 'MM-dd-yyyy'
    }
    case 'DD_MM_YYYY': {
      return 'dd-MM-yyyy'
    }
    case 'DD_MON_YYYY': {
      return 'dd-MMM-yyyy'
    }
    case 'MON_DD_YYYY': {
      return 'MMM-dd-yyyy'
    }
    default: {
      return 'dd-MM-yyyy'
    }
  }
}

export const convertTimeFormat = (format: TimeFormat) => {
  switch (format) {
    case 'TWELVE_HOURS': {
      return 'hh:mm a'
    }
    case 'TWENTY_FOUR_HOURS': {
      return 'HH:mm'
    }
    default: {
      return 'HH:mm'
    }
  }
}

export const getTimeFormatPlaceholder = () => {
  return 'hh:mm'
}

export const [DateAndTimeFormatProvider, useDateAndTimeFormat] = constate(
  useDateAndTimeFormatBase,
)
