import { Grid, Typography } from '@mui/material'
import { IconCalendar, IconLink } from 'assets/icons'
import { IconBlock } from 'components/common'
import { DetailsItemContainer } from 'components/templates/details-template'
import { differenceInYears } from 'date-fns'
import { useDateAndTimeFormat } from 'lib/context'
import { useTranslation } from 'react-i18next'

type Props = {
  birthDate?: Date
  source?: string
}

export const ContactDetailsSection = ({ birthDate, source }: Props) => {
  const { t } = useTranslation()

  const { formatDate } = useDateAndTimeFormat()

  return (
    <DetailsItemContainer title={t('common.details')}>
      <Grid container spacing={3} alignItems="flex-start">
        {birthDate && (
          <Grid item xs={4}>
            <IconBlock
              label={`${t('common.birthday')}:`}
              icon={<IconCalendar />}
              text={`${formatDate(birthDate)} (${t('common.years_old', {
                years: differenceInYears(new Date(), birthDate),
              })})`}
            />
          </Grid>
        )}

        {source && (
          <Grid item xs={8}>
            <IconBlock
              label={`${t('common.source')}:`}
              icon={<IconLink />}
              text={
                <Typography variant="body2" noWrap>
                  {source}
                </Typography>
              }
            />
          </Grid>
        )}
      </Grid>
    </DetailsItemContainer>
  )
}
