import { Box } from '@mui/material'
import { JobPerformingStatuses } from 'api'
import { ThemeColor } from 'app/mui-theme'
import { useDateAndTimeFormat } from 'lib/context'

type Props = {
  targetDate: Date
  performing: JobPerformingStatuses
}

/**  Applies appropriate color for target date based on current date or date the job was closed */
export const JobTargetDate = ({ targetDate, performing }: Props) => {
  const { formatDate } = useDateAndTimeFormat()

  const color = (): ThemeColor | 'transparent' => {
    switch (performing) {
      case 'TIMELY': {
        return 'transparent'
      }
      case 'UPCOMING': {
        return '#FFF6E6'
      }
      case 'APPROACHING': {
        return 'flushOrange[100]'
      }
      case 'OVERDUE': {
        return '#FEE6E7'
      }
    }
  }

  return (
    <Box component="span" bgcolor={color} borderRadius="4px" p="4px">
      {formatDate(targetDate, { timeZone: 'UTC' })}
    </Box>
  )
}
