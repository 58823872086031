import { Box, Stack } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { ContactCommentsItem, getContactComments, getMyProfile, qk } from 'api'
import { isEmpty } from 'fp-ts/lib/Array'
import { useInfinitePaginatedQuery } from 'lib/react-query-utils'
import { useBoolean } from 'lib/react-utils'
import { useState } from 'react'

import { AddCommentForm } from './add-comment-form'
import { Comment } from './comment'
import { CommentsSectionLayout } from './comments-section-layout'
import { EditCommentForm } from './edit-comment-form'
import {
  EmptyCommentsContent,
  EmptyCommentsSection,
} from './empty-comments-section'

type Props = {
  contactId: string
}

export const ContactCommentsSection = ({ contactId }: Props) => {
  const showEmptyCommentsWithSendInput = useBoolean()

  const $profile = useQuery(qk.auth.users.myProfile.toKey(), getMyProfile)
  const $comments = useInfinitePaginatedQuery(
    qk.contacts.comments,
    getContactComments,
    {
      pagination: { page: 0, pageSize: 10 },
      order: {
        column: 'percentage',
        direction: 'asc',
      },
      profileId: contactId,
    },
  )

  const [editedComment, setEditedComment] =
    useState<ContactCommentsItem | null>(null)

  if ($comments.isLoading) {
    return null
  }

  const comments = $comments.data?.pages.flatMap(page => page.rows)

  if (
    ((comments && isEmpty(comments)) || !comments) &&
    showEmptyCommentsWithSendInput.isFalse
  ) {
    return (
      <EmptyCommentsSection
        onLeaveComment={showEmptyCommentsWithSendInput.setTrue}
      />
    )
  }

  if (!comments || !$profile.data) {
    return null
  }

  return (
    <CommentsSectionLayout
      commentsForm={
        editedComment ? (
          <EditCommentForm
            comment={editedComment}
            onEdit={() => {
              setEditedComment(null)
              $comments.refetch()
            }}
            onCancel={() => setEditedComment(null)}
          />
        ) : (
          <AddCommentForm contactId={contactId} refetch={$comments.refetch} />
        )
      }
    >
      {isEmpty(comments) ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
          }}
        >
          <EmptyCommentsContent />
        </Box>
      ) : (
        <Box>
          <Stack spacing="16px">
            {comments.map((comment, index) => {
              return (
                <Comment
                  comment={comment}
                  key={comment.id}
                  isLast={comments.length === index + 1}
                  isMyComment={
                    comment.creatorProfile.profileId === $profile.data.userId
                  }
                  refetch={$comments.refetch}
                  onLastVisible={
                    $comments.hasNextPage ? $comments.fetchNextPage : undefined
                  }
                  onOpenEditionForm={() => setEditedComment(comment)}
                />
              )
            })}
          </Stack>
        </Box>
      )}
    </CommentsSectionLayout>
  )
}
